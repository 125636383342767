import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import DecalsSignsImg from "../../components/images/wraps-decals/decals-signs"

export default function DecalsSigns() {
    return (
        <>
            <Layout>
                <SEO title="Decals & Signs" />
                <Display
                    title="Decals & Signs"
                    description="For maximum effect, make sure your vinyl decals and signs get the attention they need, while following your branding guidelines. Talk to us about our wide selection of decals for indoor, outdoor or mobile use."
                    prev="/wraps-decals/wraps"
                    next="/wraps-decals/wraps"
                    image={<DecalsSignsImg />}
                />
            </Layout>                
        </>
    )
}
